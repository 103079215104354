.map-content-info > div {
  width: 100% !important;
}

.marker-label {
  background: var(--white-01-color);
  color: var(--dark-01-color);
  font-size: 15px;
  font-weight: 600;
  padding: 10px 35px;
  border-radius: 10px;
  transition: all 0.5s;
  width: fit-content;
}

.marker-label.active {
  background: var(--main-01-color);
  color: var(--white-01-color) !important;
}
.gm-style .gm-style-iw-c {
  padding: 0 !important;
  background: transparent;
  border: none;
  box-shadow: none;
}
.gm-style-iw-d {
  overflow: initial !important;
}

.gm-style-iw-chr {
  position: absolute;
  z-index: 1;
  right: 0;
  background: #ff2222;
  border-radius: 0 16px 0px 10px;
  color: var(--white-01-color);
  top: 1px;
}

.gm-style-iw-chr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--white-01-color);
  opacity: 1;
}
.gm-ui-hover-effect {
  opacity: 1;
}
.gm-ui-hover-effect > span {
  background-color: var(--white-01-color);
  opacity: 1;
}

:is(.gm-style .gm-style-iw-tc, .gm-style .gm-style-iw-c) {
  top: -12px;
}

.map-full .gm-fullscreen-control {
  display: none !important;
}

.map-full > div {
  border-radius: var(--border-radius-16);
}

.btn-fullscreen {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
  width: 45px;
  height: 45px;
  background: var(--white-01-color);
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active-full .card-product-one {
  display: flex;
  height: 178px !important;
  width: 430px;
  gap: 12px;
}

.active-full .card-product-one img {
  width: 134px !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.active-full .card-product-one .details-card-product .details-product {
  padding: 12px 0;
  margin: 12px 0;
}

.active-full .card-product-one .badge-info-text,
.active-full .card-product-one .details-card-product .delivery-date {
  display: none !important;
}

.active-full .gm-style-iw-chr {
  left: 0;
  right: initial;
  border-radius: 16px 0px 10px 0px;
}
.map-full .card-product-one {
  border: none;
}
@media screen and (max-width: 991px) {
  .map-full .gm-style-iw-d > div {
    width: 450px !important;
  }
  .map-full .gm-style-iw-c,
  .map-full .gm-style-iw {
    max-width: fit-content !important;
  }
  .map-full .card-product-one {
    display: flex;
    height: 178px !important;
    width: 100%;
    gap: 12px;
  }

  .map-full .card-product-one img {
    width: 134px !important;
    height: 100% !important;
    border-radius: 0 !important;
  }

  .map-full .card-product-one .details-card-product .details-product {
    padding: 12px 0;
    margin: 12px 0;
  }

  .map-full .card-product-one .badge-info-text,
  .map-full .card-product-one .details-card-product .delivery-date {
    display: none !important;
  }

  .map-full .gm-style-iw-chr {
    left: 0;
    right: initial;
    border-radius: 16px 0px 10px 0px;
  }
}

@media screen and (max-width: 767px) {
  .map-full .gm-fullscreen-control {
    display: block !important;
  }
  .btn-fullscreen {
    display: none;
  }

  .map-full .gm-style-iw-d > div {
    width: 480px !important;
  }
}

@media screen and (max-width: 480px) {
  .map-full > div {
    height: 65vh !important;
  }
}
