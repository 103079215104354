.section-title-content {
    padding-bottom: 25px;
}

.section-title-content .title {
    font-size: 25px;
    font-weight: 600;
    color: var(--dark-01-color);

}


/* SCREEN 550PX */
@media screen and (max-width:550px) {
    .section-title-content .title{
        font-size: 22px;
    }
}

