/* START LANGUGE DROPDOWN */


:is(.lang-drop, .lang-default) {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
  
}

.lang-drop {
    color: var(--dark-01-color);
    padding-right: 4px;

}

.dropmenu-lang .btn:hover {
    background: transparent !important;
    border: transparent !important;
    box-shadow: none !important;

}

:is(.dropmenu-lang) .dropdown-menu {
    --bs-dropdown-min-width: 7.5rem !important;
    --bs-dropdown-padding-y: 0rem !important;
    border-radius: 0 0 8px 8px !important;
    border: var(--border-02) !important;
    transform: translateX(27%);
}

.dark-mode :is(.dropmenu-lang) .dropdown-menu {
    background: #232423;
}

:is(.dropmenu-lang) .dropdown-menu::before {
    content: "";
    position: absolute;
    top: -21px;
    transform: translateX(-50%);
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ededed transparent;
}

.dark-mode :is(.dropmenu-lang) .dropdown-menu::before {
    border-color: transparent transparent #484c48 transparent;

}

:is(.dropmenu-lang) .dropdown-menu .dropdown-item {
    border-radius: 0 !important;
    background: transparent !important;
    padding: 10px;
    transition: all 0.5s;

}

:is(.dropmenu-lang) .dropdown-menu .dropdown-item .link-drop-item {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-01-color) !important;
}

:is(.dropmenu-lang) .dropdown-menu .dropdown-item:nth-child(1) .link-drop-item svg {
    fill: var(--dark-01-color);
}

:is(.dropmenu-lang) .dropdown-menu .dropdown-item:hover {
    color: var(--green-01-color);
}

:is(.dropmenu-lang) .dropdown-item:nth-child(1) {
    border-bottom: var(--border-02);
}

:is(.dropmenu-lang .drop-lang) {
    padding: 0;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

:is(.dropmenu-lang) .dropdown-toggle::after {
    display: none;
}

:is(.dropmenu-lang .drop-lang) .btn-check:checked+.btn,
:is(.dropmenu-lang .drop-lang) .btn.active,
:is(.dropmenu-lang .drop-lang) .btn.show,
:is(.dropmenu-lang .drop-lang) .btn:first-child:active :not(.dropmenu-lang .drop-lang .btn-check)+.dropmenu-lang .drop-lang.btn:active,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    background: transparent !important;
    border: none !important;
}

:is(.dropmenu-lang) .dropdown-menu[data-bs-popper] {
    margin-top: 15px;
}

:is(.dropmenu-lang) .dropdown-item.active,
:is(.dropmenu-lang) .dropdown-item:active {
    background: transparent !important;
    color: var(--dark-01-color);
}


/* END LANGUGE DROPDOWN */