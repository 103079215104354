.form-check-input--1 {
  padding-left: 1.5rem;
  margin: 0 !important;
  padding-block: 10px;
}
.form-check-input--1 .form-check-label {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.form-check-input--1 label {
  margin: 0 !important;
}
