.images-slide-show {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  height: 100%;
}

.images-slide-show img{
width: 100%;
height: 100%;
object-fit: cover;
border-radius: var(--border-radius-16);
}

.large-image-bg img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: var(--border-radius-16);
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  display: none;
}

.lg-components {
  bottom: 22px !important;
}

.images-slide-show .image-src {
  display: none;
  position: relative;
}

.images-slide-show>.image-src:nth-child(1),
.images-slide-show>.image-src:nth-child(2),
.images-slide-show>.image-src:nth-child(3),
.images-slide-show>.image-src:nth-child(4) {
  display: block;
}

.images-slide-show>.image-src:nth-child(4)::after {
  content: "+3";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.575);
  top: 0;
  left: 0;
  border-radius: var(--border-radius-16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--white-01-color);
}

.lg-outer :is(.lg-prev, .lg-next) {
  background: var(--white-01-color) !important;
  transition: all 0.5s;
  border-radius: 8px;
}

.lg-outer :is(.lg-prev, .lg-next):hover {
  color: var(--dark-01-color) !important;
}

#lg-content-2 .lg-img-wrap img {
  width: 50% !important;
  height: 100% !important;
  border-radius: var(--border-radius-16);
}

@media screen and (max-width: 1199px) {
  #lg-content-2 .lg-img-wrap img {
    width: 60% !important;
  }
}

@media screen and (max-width: 991px) {
  #lg-content-2 .lg-img-wrap img {
    width: auto !important;
  }
}

@media screen and (max-width: 550px) {
  #lg-content-2 .lg-img-wrap img {
    width: 90% !important;
    height: 70% !important;
    object-fit: cover;
  }

  .lg-outer .lg-thumb {
    margin-bottom: 50px !important;
  }
}