.google-map-content .google-map-1 {
  height: 400px;
  width: 100%;
  border-radius: var(--border-radius-24);
  border: var(--border-04);
  overflow: hidden;
}

.gm-style>div {
  border: none !important;
}

.google-map-content .title {
  font-size: 17px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-top: 20px;
}

.google-map-content .text {
  font-size: 15px;
  font-weight: 400;
  color: var(--dark-01-color);
  line-height: 1.8;
  padding-top: 12px;
}


.about-tab .list-content .btn-main {
  background: var(--light-blue-01-color);
  color: var(--dark-01-color);
  border-color: var(--light-blue-01-color);
  font-size: 14px;
}

.google-map-content .text {
  font-size: 14px;
}

.content-marker .text-marker {
  font-size: 17px;
  font-weight: 600;
  background: rgba(17, 57, 177, 0.671);
  width: max-content;
  padding: 15px;
  border-radius: 15px;
  color: var(--white-01-color);
}

.content-marker .image-marker {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 31, 126, 0.281);
}