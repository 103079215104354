.not-data-found .title {
    font-size: 20px;
    font-weight: 600;
    color: #d10e0e;
    text-align: center;
    text-transform: capitalize;
    padding: 25px 0;
}

@media screen and (max-width:450px) {
    .not-data-found .title {
        font-size: 17px;
        font-weight: 800;
       
    }
}