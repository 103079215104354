.contact-details .image-contact img {
  border-radius: var(--border-radius-24);
}

.form-contact-content .top-header-form-info .title {
  font-size: 23px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.form-contact-content .top-header-form-info .text {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-01-color);
  padding: 20px 0;
}

.form-contact-content .btn-submit {
  border-radius: 50px;
  padding: 13px 24px !important;
}

.form-contact-content :is(.input-field-info, .input-phone-number) {
  margin-bottom: 18px;
}

.contact-details .form-contact-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* SCREEN 991PX */
@media screen and (max-width: 991px) {
  .form-style--1 {
    padding: 30px 15px;
  }
}

/* SCREEN 991PX */
@media screen and (max-width: 991px) {
  .form-contact-content .top-header-form-info .title {
    font-size: 20px;
  }

  .form-contact-content .top-header-form-info .text {
    font-size: 14px;
  }
}

.input-phone-number .react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
  border-right: var(--border-04);
  background: transparent;
}

.input-phone-number .form-control {
  padding: 24px 30px 24px 65px !important;
}

.input-phone-number .react-tel-input .selected-flag {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px 0 0;
  background: transparent !important;
}

.input-phone-number .react-tel-input .selected-flag:hover,
.input-phone-number .react-tel-input .selected-flag:focus {
  background: transparent !important;
}
