@font-face {
  font-family: nexaLight;
  src: url(./assets/webfont/nexa/Nexa\ Light.otf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: nexaRegular;
  src: url(./assets/webfont/nexa/Nexa\ Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: nexaBold;
  src: url(./assets/webfont/nexa/Nexa\ Bold.otf);
  font-weight: 800;
  font-style: normal;
}


* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none !important;
  font-family: "nexaRegular", sans-serif;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

:root {
  --main-01-color: #00207e;
  --light-blue-01-color: #f4f8fa;
  --warning-01-color: #dfc500;
  --green-01-color: #32a840;
  --dark-01-color: #212121;
  --white-01-color: #ffffff;
  --gray-01-color: #969595;
  --box-shadow: 0px 30px 70px #524c641f;
  --border-01: 1px solid #ffffff;
  --border-02: 1px solid #e5e7e7;
  --border-03: 1px solid #f4f8fa;
  --border-04: 1px solid #dcdcdc;
  --border-radius-24: 24px;
  --border-radius-16: 16px;
  --border-radius-8: 8px;
}

body {
  background: var(--white-01-color);
  min-height: 100vh;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 0 !important;
}

.nexaBold-font {
  font-family: nexaBold;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}

img {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.cursor-pointer-event {
  cursor: pointer;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-30 {
  padding-top: 30px;
}

.modal.show {
  padding-left: 0 !important;
}

.btn-main {
  padding: 13px 21px;
  font-size: 16px;
  font-weight: 500;
  background: var(--main-01-color);
  color: var(--white-01-color);
  border: var(--border-01);
  border-color: var(--main-01-color);
  border-radius: 12px;
  box-shadow: var(--box-shadow-1);
  appearance: button;
  backface-visibility: hidden;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: all 0.5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  text-align: center;
  width: fit-content;
  text-transform: capitalize;
}

.btn-height {
  height: 45px;
}

.btn-transparent,
.btn-transparent-2 {
  background: var(--light-blue-01-color);
  color: var(--dark-01-color);
  border-color: var(--light-blue-01-color);
  transition: all 0.5s;
}

.btn-transparent:hover {
  background: var(--main-01-color);
  color: var(--white-01-color);
  border-color: var(--main-01-color);
}

.btn-transparent svg path {
  transition: all 0.5s;
}

.btn-transparent:hover svg path {
  stroke: var(--white-01-color);
}

main {
  position: relative;
  z-index: 0;
}

.form-control {
  text-align: right;
}

/* START FORM INPUT */
.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-position: right calc(0.375em + 0.1875rem) center !important;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem) !important;
}

.form-style {
  background: var(--white-01-color);
  padding: 30px;
  border-radius: 16px;
  box-shadow: var(--box-shadow-1);
  border: var(--border-04);
  /* height: 100%; */
}

.input-pass .form-control.is-invalid,
.was-validated .input-pass .form-control:invalid {
  background-image: url("");
}

.error {
  font-size: 14px;
  font-weight: 400;
  color: #ff0000;
  padding-top: 5px;
}

input::placeholder,
textarea::placeholder,
.form-select {
  font-size: 14px;
  color: #a8b1ce !important;
  font-weight: 500;
  text-align: left;
  text-transform: capitalize;
}

.form-select option {
  color: var(--dark-01-color);
  font-size: 15px;
  font-weight: 500;
}

input,
textarea,
.form-select,
.form-control {
  padding: 10px 12px !important;
  text-align: left;
  border: 1.5px solid #e5e7e7 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  border-right: var(--border-02) !important;
}

.active-border {
  border-color: #148035 !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: rgb(255, 17, 17) !important;
}

label {
  font-size: 15px;
  color: var(--dark-01-color);
  font-weight: 400;
  text-transform: capitalize;
  margin: 0.3rem 0 0 0 !important;
}

textarea {
  resize: none !important;
}

textarea::placeholder {
  text-align: left;
}

.form-one {
  margin-top: 10px !important;
}

.form-check-input {
  margin-top: 0 !important;
}

.form-check-input:checked {
  background-color: var(--main-01-color) !important;
  border-color: var(--main-01-color) !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 115, 0.18) !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.form-select {
  margin: 10px 0 5px 0;
}

.form-switch {
  display: flex !important;
  align-items: center;
  gap: 15px;
  padding: 0 !important;
}

.form-switch .form-check-input {
  padding: 13px !important;
  width: 3em !important;

  margin: 0 !important;
}

.form-check {
  padding-left: 0.8rem;
  padding-right: 0;
}

.form-check .form-check-input {
  margin-left: -1.5em;
  margin-right: 0;
}

/* END FORM INPUT */
.Toastify__toast {
  border-radius: 6px !important;
}

.Toastify__toast-body > div:last-child {
  font-size: 15px;
  font-weight: 500;
}

/* SCREEN 480PX */
@media screen and (max-width: 480px) {
  .Toastify__toast-container--rtl {
    padding: 10px !important;
  }

  .Toastify__toast-theme--colored {
    margin-bottom: 10px !important;
  }
}

@media screen and (max-width: 420px) {
  .btn-main-full {
    width: 100%;
  }
}

/* HELP CLASS */
.modal-width-content {
  --bs-modal-width: 950px !important;
}

/* HELP CLASSESS */

.overlay-bg-image::after,
.overlay-bg-image--2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.219);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay-bg-image--2::after {
  background: rgba(0, 0, 0, 0.45);
  border-radius: var(--border-radius-24);
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  position: absolute !important;
  z-index: 1 !important;
}
