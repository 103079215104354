.navbar-menu {
  padding: 17px 0 !important;
  border-bottom: var(--border-02);
}

.navbar-menu .nav-menu .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-01-color);
  transition: all 0.5s;
  text-transform: capitalize;
}

.home-bg .navbar-menu .nav-menu .nav-link {
  color: var(--white-01-color);
}

.navbar-menu .nav-menu .nav-link.active {
  color: var(--main-01-color) !important;
}

.home-bg .navbar-menu {
  border-bottom: none;
}

.navbar-menu .navbar-brand {
  padding-top: 0 !important;
  margin: 0;
}

.navbar-menu .navbar-nav {
  align-items: center;
}

.navbar-menu .navbar-toggler {
  padding: 7px 10px;
  border: var(--border-04);
}

.home-bg .navbar-menu .navbar-toggler:focus,
.navbar-menu .navbar-toggler:focus {
  box-shadow: none !important;
  border-color: var(--white-01-color);
}

.navbar-menu .navbar-toggler:focus {
  border-color: var(--main-01-color);
}

.navbar-menu.menu-fixed .navbar-toggler:focus {
  border: var(--border-04) !important;
}

.home-bg .navbar-menu {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-menu.menu-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  animation: slideDown 0.5s ease;
  border-bottom: var(--border-02);
  background: var(--white-01-color);
  padding: 15px 0 !important;
  z-index: 300 !important;
}

/* ANIMATION ON SCROLL MENU NAVBAR */
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.navbar-menu.menu-fixed .nav-menu .nav-link {
  color: var(--dark-01-color);
}

.navbar-menu.menu-fixed :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
.navbar-menu :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
.home-bg
  .navbar-menu.menu-fixed
  :is(.dropmenu-lang)
  .dropdown-menu[data-bs-popper] {
  margin-top: 28px;
}

.home-bg .navbar-menu :is(.dropmenu-lang) .dropdown-menu[data-bs-popper] {
  margin-top: 15px;
}
.home-bg .navbar-menu .lang-default {
  color: var(--white-01-color);
}

.navbar-menu.menu-fixed .lang-default {
  color: var(--dark-01-color);
}

.navbar-menu .main-info-left {
  position: relative;
  z-index: 1001;
  padding-left: 40px;
}

.navbar-menu .main-info-left .dropmenu-lang {
  display: none;
}

.navbar-toggler-icon {
  background-image: url("../../assets/images/iconsSvg/bars2.svg") !important;
}

.navbar-menu.menu-fixed .navbar-toggler-icon {
  background-image: url("../../assets/images/iconsSvg/bars2.svg") !important;
}

.home-bg .navbar-toggler-icon {
  background-image: url("../../assets/images/iconsSvg/bars.svg") !important;
}

.all-main-navbar {
  width: 100%;
}

.all-main-navbar .icon-close-menu {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar-menu.menu-fixed :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
  .navbar-menu :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
  .home-bg
    .navbar-menu.menu-fixed
    :is(.dropmenu-lang)
    .dropdown-menu[data-bs-popper] {
    margin-top: 25px;
  }
  .navbar-menu .nav-menu .nav-link {
    color: var(--dark-01-color);
  }

  .navbar-menu.menu-fixed .nav-menu .nav-link {
    color: var(--dark-01-color);
  }

  .navbar-menu.menu-fixed .nav-menu .nav-link.active {
    color: var(--main-01-color);
  }

  .navbar-menu .main-info-left .dropmenu-lang {
    display: block;
  }

  .navbar-menu .left-nav-menu .dropmenu-lang {
    display: none;
  }

  .navbar-menu .nav-menu .navbar-nav {
    padding: 15px 0;
    gap: 0px !important;
    align-items: initial !important;
  }

  .navbar-menu .nav-menu .navbar-nav .btn-main {
    margin: 10px 0 5px 0;
    width: fit-content !important;
  }

  :is(
      .all-main-navbar,
      .navbar-collapse,
      .navbar-collapse .overlay-menu,
      .all-main-navbar .collapse
    ) {
    position: fixed;
    top: 0;
    height: 120vh !important;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    bottom: 0;
  }

  .all-main-navbar .overlay-menu {
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    left: -120% !important;
    transition: left 0.5s ease-in-out;
  }

  .all-main-navbar .overlay-menu.show-over {
    left: 0 !important;
  }

  .all-main-navbar .collapse:not(.show) {
    display: initial !important;
  }

  .all-main-navbar .collapse.show {
    display: initial !important;
  }

  .all-main-navbar {
    position: fixed;
    width: 100%;
    left: -120% !important;
    transition: left 0.5s ease-in-out;
  }

  .all-main-navbar.show-main {
    left: 0 !important;
  }

  .all-main-navbar .navbar-collapse {
    position: fixed;
    width: 40%;
    left: -120% !important;
    background-color: var(--white-01-color);
    transition: left 0.7s ease-in-out;
    padding: 50px 15px 15px 15px;
  }

  .dark-mode .all-main-navbar .navbar-collapse {
    background: #1a1a1a !important;
  }

  .all-main-navbar .navbar-collapse.show--1 {
    left: 0 !important;
  }

  .all-main-navbar .navbar-collapse .navbar-nav {
    gap: 20px !important;
  }

  .all-main-navbar .navbar-collapse .navbar-nav .nav-link {
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-01-color);
  }

  .all-main-navbar .icon-close-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    background: var(--main-01-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: #fff;
    border-radius: 0 0 0 8px;
  }

  .all-main-navbar .btn-main,
  .navbar-menu .nav-menu .navbar-nav .btn-main--2 {
    width: 100% !important;
    font-weight: bold;
    margin-top: 20px;
  }

  .navbar-menu.menu-fixed .btn-main:hover {
    background: var(--main-01-color);
    color: var(--white-01-color);
  }
}

@media screen and (max-width: 854px) {
  .all-main-navbar .navbar-collapse {
    width: 50%;
  }
}

@media screen and (max-width: 683px) {
  .all-main-navbar .navbar-collapse {
    width: 70%;
  }
}

@media screen and (max-width: 550px) {
  .navbar-menu.menu-fixed :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
  .navbar-menu :is(.dropmenu-lang) .dropdown-menu[data-bs-popper],
  .home-bg
    .navbar-menu.menu-fixed
    :is(.dropmenu-lang)
    .dropdown-menu[data-bs-popper] {
    margin-top: 23px;
  }
  .all-main-navbar .navbar-collapse {
    width: 80%;
  }

  .navbar-menu .navbar-toggler {
    padding: 5px 8px;
  }
}

@media screen and (max-width: 480px) {
  .all-main-navbar .navbar-collapse {
    width: 95%;
  }

  .navbar-menu .nav-menu .navbar-nav .btn-main {
    width: 100% !important;
  }
}

/* SCREEN 359PX */
@media screen and (max-width: 359px) {
  .left-nav-menu {
    flex-direction: column-reverse;
    align-items: initial !important;
  }
}
