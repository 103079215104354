.copy-text-success {
  background: rgba(50, 168, 64, 0.1);
  color: var(--dark-01-color);
  font-size: 15px;
  font-weight: 500;
  padding: 10px 14px;
  border-radius: 8px;
  position: absolute;
  width: max-content;
  top: 50%;
  right: 100%;
  margin-right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  z-index: 100;
}

.all-content-copy .social-icons-info-share {
  position: absolute;
  top: 50%;
  right: -350%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  opacity: 0;
}
.all-content-copy .social-icons-info-share.active {
  right: 50%;
  opacity: 1;
}

.all-content-copy .social-icons-info-share .icon-social-one {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1877f2;
  color: var(--white-01-color);
  border-radius: 50%;
}

.all-content-copy .social-icons-info-share .icon-social-one:nth-child(2) {
  background: #1da1f2;
}
.all-content-copy .social-icons-info-share .icon-social-one:nth-child(3) {
  background: #c32aa3;
}

.all-content-copy .social-icons-info-share .icon-social-one:nth-child(4) {
  background: #25d366;
}
@media screen and (max-width: 625px) {
  .all-content-copy .social-icons-info-share {
    top: 120%;
    transform: translate(0);
  }

  .all-content-copy .social-icons-info-share.active {
    right: 0;
  }
}
