.header-content-top .results-product-count {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}


@media screen and (max-width:450px) {
  .header-content-top .right-content-top{
    justify-content: space-between;
    width: 100%;
  }
}