.master-plan img {
    border-radius: 24px;
    width: 100%;
    height: 450px;
    object-fit: cover;
}

@media screen and (max-width:767px) {
    .master-plan img {
        height: 100%;
    }
}