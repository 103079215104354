.header-about .info-top-content {
  text-align: center;
  max-width: 950px;
  width: 100%;
  margin: auto;
}

.header-about .info-top-content :is(.title-text, .text) {
  font-size: 20px;
  font-weight: 400;
  color: var(--dark-01-color);
}

.main-about-content:nth-child(1) div > .main-content-info {
  margin-left: 65px;
}
.main-about-content:nth-child(2) div > .main-content-info {
  margin-right: 65px;
}
.header-about .info-top-content .title {
  font-size: 40px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding: 10px 0 18px 0;
  text-transform: capitalize;
}

.header-about .info-top-content .text {
  font-size: 15px;
  line-height: 1.8;
}

.header-about .image-about-header {
  margin-top: 35px;
}

.header-about .image-about-header img,
.header-about .image-about-header::after {
  border-radius: var(--border-radius-24);
  object-position: top;
}

.header-about .image-about-header::after {
  background: rgb(0 0 0 / 55%);
}

/* SCREEN 1199PX */
@media screen and (max-width: 1199px) {
  .main-about-content:nth-child(1) div > .main-content-info {
    margin-left: 0px;
  }
  .main-about-content:nth-child(2) div > .main-content-info {
    margin-right: 0px;
  }
}

/* SCREEN 767PX */
@media screen and (max-width: 767px) {
  .header-about .info-top-content .title {
    font-size: 30px;
  }
}
/* SCREEN 550PX */
@media screen and (max-width: 550px) {
  .header-about .info-top-content .title {
    font-size: 20px;
  }

  .header-about .image-about-header img {
    height: 350px;
    object-position: initial;
  }
}
