html[dir="rtl"] .btn-main svg,
html[dir="rtl"] .page-item .page-link svg {
  transform: rotateY(180deg) ;
}

/* START FORMS */

html[dir="rtl"]
  :is(input, textarea, .form-select, .form-control, label, .form-label) {
  text-align: right !important;
}

html[dir="rtl"] :is(input, textarea)::placeholder {
  text-align: right !important;
}

html[dir="rtl"] .input-field-info label {
  left: initial;
  right: 10px;
}

html[dir="rtl"] .input-phone-number .form-control {
  padding: 24px 65px 24px 30px !important;
}

html[dir="rtl"] .input-phone-number .react-tel-input .flag-dropdown {
  border: none !important;
  border-left: var(--border-02) !important;
}

html[dir="rtl"] .react-tel-input .selected-flag .arrow {
  left: 13px;
}

html[dir="rtl"] .form-control.is-invalid,
html[dir="rtl"] .was-validated .form-control:invalid {
  background-position: left calc(0.375em + 0.1875rem) center !important;
}

html[dir="rtl"] .was-validated textarea.form-control:invalid,
html[dir="rtl"] textarea.form-control.is-invalid {
  background-position: top calc(0.375em + 0.1875rem) left
    calc(0.375em + 0.1875rem) !important;
}

/* END FORMS */
/* START NABAR MENU  */

html[dir="rtl"] .navbar-menu .navbar-nav {
  margin-right: initial !important;
  margin-left: auto;
}

html[dir="rtl"] .navbar-menu .navbar-nav .nav-link {
  font-weight: 600;
}

html[dir="rtl"] .navbar-menu .main-info-left {
  padding: 0 0 0 40px !important;
}

@media screen and (max-width: 991px) {
  html[dir="rtl"] .navbar-menu .main-info-left {
    padding: 0px !important;
  }
}

/* END NABAR MENU  */
/* START FOOTER */
html[dir="rtl"]
  .footer
  .middle-content-footer
  .list-links-text
  .link-text:hover {
  transform: translateX(-8px);
}

/* END FOOTER */
/* START HEADER HOME */
html[dir="rtl"] .filter-category .button-drop::after {
  left: 0;
}

html[dir="rtl"] .filter-category .search-filter {
  margin: 0 0 0 0;
}

html[dir="rtl"] .filter-category {
  padding: 0 0 0 32px;
}

@media screen and (max-width: 991px) {
  html[dir="rtl"] .filter-category {
    padding: 25px;
  }
}

@media screen and (max-width: 515px) {
  html[dir="rtl"] .filter-category {
    padding: 25px 15px;
  }
}

/* END HEADER HOME */

/* START BANNER HOME */
html[dir="rtl"] .main-banner-home .content-slide .title {
  text-align: right;
}

html[dir="rtl"] .main-banner-home .content-slide {
  padding: 0 50px 0 0;
}

/* END BANNER HOME */

/* START PAGE ABOUT */
html[dir="rtl"] .main-about-content:nth-child(1) div > .main-content-info {
  margin: 0 65px 0 0;
}

html[dir="rtl"] .main-about-content:nth-child(2) div > .main-content-info {
  margin: 0 0 0 65px;
}

html[dir="rtl"] .text-500 {
  font-weight: 500 !important;
}

@media screen and (max-width: 767px) {
  html[dir="rtl"] .header-about .info-top-content .title {
    font-size: 33px;
  }
}

/* END PAGE ABOUT */

/* START FAQ  */
html[dir="rtl"] .accordion-button::after {
  margin: 0 auto 0 0 !important;
}

html[dir="rtl"] .faq-home .all-faq-home .faq-one .accordion-body {
  text-align: right !important;
}

/* END FAQ  */

/* START PAGE RESULTS */
html[dir="rtl"] .btn-fullscreen {
  right: 15px;
}

html[dir="rtl"] .active-full .gm-style-iw-chr {
  right: 0 !important;
  left: initial !important;
  border-radius: 0px 0px 0px 10px;
}

/* END PAGE RESULTS */
/* START COPY BUTTON */

html[dir="rtl"] .all-content-copy .social-icons-info-share {
  left: -350%;
  right: initial !important;
}

html[dir="rtl"] .all-content-copy .social-icons-info-share.active {
  left: 200%;
  right: initial !important;

  opacity: 1;
}

@media screen and (max-width: 625px) {
  html[dir="rtl"] .all-content-copy .social-icons-info-share.active {
    right: initial !important;
    left: 0 !important;
  }
}

html[dir="rtl"] .lg-outer .lg-inner,
html[dir="rtl"] .lg-outer .lg-thumb-outer {
  direction: ltr;
}

/* END COPY BUTTON */

html[dir="rtl"] .price-filter-drop .main-price-input {
  text-align: right !important;
}

/* START CARD PRODUCT */
html[dir="rtl"]
  .card-product-one
  .details-card-product
  .details-product
  .details-one-content:not(:last-child):after {
  left: -37%;
  right: initial;
}

@media screen and (max-width: 550px) {
  html[dir="rtl"]
    .card-product-one
    .details-card-product
    .details-product
    .details-one-content:not(:last-child):after {
    left: -55%;
    right: initial;
  }
}

/* END CARD PRODUCT */


@media screen and (min-width: 1200px) {
    html[dir="rtl"] .details-page-body {
        overflow: hidden !important;
    }
}