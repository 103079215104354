.modal-open {
  padding: 0 !important;
}

.modal .modal-header {
  justify-content: space-between;
  padding: 10px 20px !important;
}

.modal .modal-title {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.btn-close-icon {
  width: 45px;
  height: 45px;
  border: var(--border-02) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  font-size: 17px !important;
  color: var(--main-01-color) !important;
  cursor: pointer;
  border-radius: 50% !important;
  border-width: 2px !important;
  transition: all 0.5s !important;
}

.btn-close-icon:hover {
  color: var(--white-01-color) !important;
  background: var(--main-01-color) !important;
  border-color: var(--main-01-color) !important;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modal .modal-content {
  border-radius: 16px;
  box-shadow: var(--box-shadow-1);
  padding-bottom: 15px;
}

/* SCREEN 1024PX */
@media screen and (max-width: 1024px) {
  .modal .modal-content {
    margin: 0 10px;
  }
}

/* SCREEN 575PX */
@media screen and (max-width: 575px) {
  .modal .modal-content {
    margin: 0;
  }
}

@media screen and (max-width: 420px) {
  .btn-close-icon {
    width: 38px;
    height: 38px;
    font-size: 15px !important;
  }
}
