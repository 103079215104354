.breadcrumb-page {
  padding-top: 20px;
}
.breadcrumb-page :is(.breadcrumb-item, .breadcrumb-item .link-bread) {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-03-color);
  transition: all 0.5s;
}

.breadcrumb-page .breadcrumb-item.active,
.breadcrumb-page .breadcrumb-item:hover .link-bread,
.breadcrumb-page-route .breadcrumb .breadcrumb-item:nth-child(3) .link-bread {
  color: var(--main-01-color) !important;
}

.breadcrumb-page .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}
