.right-content-details-pro {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky !important;
  top: 73px !important;
  padding: 25px 0;
}
.right-content-details-pro .info-details {
  border: var(--border-04);
  padding: 25px 18px;
  border-radius: var(--border-radius-24);
}

.right-content-details-pro .price-content sub {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.right-content-details-pro .price-content .price-num {
  font-size: 20px;
  display: block;
}
.right-content-details-pro  .down-payment span {
  font-size: 16px;
  font-weight: 800;

}
.right-content-details-pro  .badge-info-text {
  background: var(--light-blue-01-color);
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--main-01-color);
  width: fit-content;
  border-radius: 50px;
  margin: 10px 0px;
}

.right-content-details-pro  .badge-info-text svg {
  width: 20px;
  height: 20px;
}
.right-content-details-pro .delivery-date {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-01-color);
  padding: 7px 0px;

  margin-top: 10px;
}
.right-content-details-pro .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark-01-color);
  padding-bottom: 10px;
}

.right-content-details-pro .text {
  font-size: 15px;
  font-weight: 400;
  color: var(--gray-01-color);
  padding-bottom: 10px;
}
.right-content-details-pro .list-items-info {
  width: 100%;
  padding-top: 15px !important;
  flex-wrap: nowrap !important;
}
.right-content-details-pro .list-items-info .btn-main {
  width: 100%;
  font-size: 14px;
  padding: 13px 17px;
}
.right-content-details-pro .list-items-info .btn-main svg {
  width: 23px;
  height: 23px;
}
.right-content-details-pro .buttons-check .btn-height {
  height: 50px;
}
.right-content-details-pro .buttons-check .btn-green {
  background: var(--green-01-color);
  border-color: var(--green-01-color);
}

.right-content-details-pro .price-end {
  margin-top: 20px;
  padding: 25px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top: var(--border-04);
}
.right-content-details-pro .price-end .price-num {
  font-size: 22px;
}

@media screen and (max-width: 1199px) {
  .right-content-details-pro .list-items-info {
    flex-wrap: wrap !important;
  }
  .right-content-details-pro .list-items-info > * {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .right-content-details-pro .list-items-info {
    flex-wrap: nowrap !important;
  }
  .right-content-details-pro .list-items-info > * {
    width: 100%;
    flex: 0 1 auto;
  }
}
@media screen and (max-width: 500px) {
  .right-content-details-pro .list-items-info .list-one-li:nth-child(1),
  .right-content-details-pro .list-items-info .list-one-li:nth-child(2) {
    width: 100%;
    flex: 0 1.5 auto;
  }
}
@media screen and (max-width: 390px) {
  .right-content-details-pro .list-items-info {
    flex-wrap: wrap !important;
  }

  .right-content-details-pro .list-items-info .list-one-li {
    flex: 100%;
    width: 100%;
  }
  .right-content-details-pro .list-items-info .btn-main {
    font-size: 17px;
  }
}
.card-product-one .details-card-product .down-payment span {
  font-size: 16px;
  font-weight: 800;
}