.filter-header-info {
  position: relative;
}
.filter-header-info::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(229, 231, 231, 1);
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 991px) {
  .filter-header-info::after {
    display: none;
  }
}
