.card-info-how-work {
    background: var(--white-01-color);
    border: var(--border-04);
    border-radius: var(--border-radius-24);
    padding: 24px;
}

.card-info-how-work .num-info-top {
    font-size: 40px;
    color: var(--dark-01-color);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.card-info-how-work .content-info-card {
    padding-top: 35px;
}

.card-info-how-work .content-info-card .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.card-info-how-work .content-info-card .text {
    font-size: 14px;
    font-weight: 500;
    color: var(--dark-01-color);
    line-height: 1.6;
    padding-top: 25px;
}

:is(.card-info-how-work,
    .card-info-how-work .title,
    .card-info-how-work .text) {
    transition: all 0.5s;
}

.card-info-how-work:hover {
    background: var(--main-01-color);
    border-color: var(--main-01-color);
}

.card-info-how-work:hover :is(.num-info-top, .title, .text) {
    color: var(--white-01-color);
}

/* SCREEN 450PX */

@media screen and (max-width:450px) {
    .card-info-how-work {
        padding: 15px;
    }

    .card-info-how-work .content-info-card .title {
        font-size: 20px;

    }

    .card-info-how-work .content-info-card .text {
        font-size: 14px;

    }
}