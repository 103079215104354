@media screen and (max-width:550px) {
   .contact-page .image-header-contact img{
    height: 300px;
   }
}

@media screen and (max-width:350px) {
    .contact-page .image-header-contact img{
     height: 250px;
    }
 }