.counter-up-about .all-counter-up {
    background: var(--light-blue-01-color);
    padding: 35px 20px;
    border-radius: var(--border-radius-16);
}

.counter-up-about .counter-one {
    width: fit-content;
    margin: auto;
    text-align: center;
}

.counter-up-about .counter-one .text-counter {
    font-size: 17px;
    font-weight: 500;
    color: var(--dark-01-color);
    text-transform: capitalize;
}

@media screen and (max-width:767px) {
    .counter-up-about .counter-one .text-counter{
    font-size: 19px;
        font-weight: 500;
    }
}