.footer {
    background: var(--light-blue-01-color);

}

.footer .all-footer {
    padding: 60px 0 30px 0;
}

/* ========== START LEFT CONTENT ============= */
.footer .left-content-footer .logo-footer img {
    width: 230px !important;
}

.footer .left-content-footer .social-icons-info .icon-social-one {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--main-01-color);
    background: var(--white-01-color);
    border-radius: 50%;
    border: var(--border-01);
    transition: all 0.5s;
}

.footer .left-content-footer .social-icons-info .icon-social-one:hover {
    background: var(--main-01-color);
    color: var(--white-01-color);
    border-color: var(--main-01-color);
}

.footer .text-copyright {
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-01-color);
    padding: 17px 0;
    text-align: center;
    border-top: var(--border-04);
}

/* ========== END LEFT CONTENT ============= */
/* ========== START MIDDLE CONTENT ========== */
.footer .middle-content-footer .list-links-text .link-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-01-color);
    transition: all 0.5s;
    padding-bottom: 15px;
    display: flex;
    text-transform: capitalize;
}

.footer .middle-content-footer .list-links-text .link-text:hover {
    color: var(--main-01-color);
    transform: translateX(8px);
}
@media screen and (max-width:767px){
    .footer .middle-content-footer{
        margin-top: 15px;
    }
    .footer .left-content-footer .logo-footer img{
        width: 150px !important;
    }
    .footer .all-footer{

        padding: 60px 0;
    }
}
/* ========== END MIDDLE CONTENT ========== */
/* ========== START RIGHT CONTENT ========= */
.footer .right-content-footer .title{
    font-size: 20px;
    font-weight: 600;
    color: var(--dark-01-color);
    text-transform: capitalize;
}

.footer .right-content-footer .form-input-one{
    display: flex;
    align-items: center;
    background: var(--white-01-color);
    padding: 5px 10px;
    border-radius: 50px;
    width: fit-content;
    border: var(--border-04);
    margin-top: 15px;
}
.footer .right-content-footer .form-input-one input{
    border: none !important;
    box-shadow: none;
    background: transparent;
    width: 100%;
}
.footer .right-content-footer .form-input-one .btn-main{
    border-radius: 50px;
    padding: 13px 40px;
    font-size: 14px;
}

@media screen and (max-width:550px) {
    .footer .right-content-footer .form-input-one{
        width: 100%;
    }
}


/* ========== END RIGHT CONTENT ========= */
