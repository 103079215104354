.btn-scroll {
  position: fixed;
  bottom: 30px;
  right: -60px;
  transition: all 0.5s;
  z-index: 0;
  width: 55px;
  height: 55px;
  background: var(--main-01-color);
  font-size: 20px;
  color: var(--white-01-color);
  border:4px solid #3256c1;
  border-radius: 50px;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-scroll.active {
  right: 30px;
}
