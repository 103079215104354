.left-modal-content .image-content img {
    height: 360px;
    border-radius: var(--border-radius-16);
}

.left-modal-content .right-content-details-pro .info-details {
    border: none;
    padding: 0;

}

.left-modal-content .right-content-details-pro {
    padding-top: 18px;
}

.left-modal-content .right-content-details-pro .list-items-info {
    padding-top: 6px !important;
}

.left-modal-content .right-content-details-pro .price-end {
    padding: 0;
    justify-content: flex-start;
    border: none;
}

.right-modal-content .form-contact-content .top-header-form-info .title {
    font-size: 20px;
    color: var(--dark-01-color);
}

.right-modal-content .form-contact-content .top-header-form-info .text {
    font-size: 13px;
}

.right-modal-content .form-style {
    padding: 25px 12px;
}

.right-modal-content .btn-main {
    width: 100%;
}

@media screen and (max-width:550px) {
    .left-modal-content .image-content img {
        height: 250px;
    }

}