#demo-popup-menu .MuiPaper-root {
  overflow-y: hidden;
  margin-top: 5px;
  border-radius: 16px;
  background: transparent;
}
#demo-popup-menu :is(.MuiPaper-root div, .MuiPaper-root ul)::-webkit-scrollbar {
  width: 10px !important;
  padding-right: 15px;
}

#demo-popup-menu :is(div, .MuiPaper-root ul)::-webkit-scrollbar-track {
  background: #f2f2f2;
}

#demo-popup-menu :is(div, .MuiPaper-root ul)::-webkit-scrollbar-thumb {
  background: var(--main-01-color);
  width: 12px !important;
  border-radius: 10px;
  height: 10px !important;
}

#demo-popup-menu ul {
  padding-right: 0 !important;
  overflow-y: auto;
  height: 250px;

  background: var(--white-01-color);

  border-radius: 16px;
}
