.dropdown---1 {
  position: relative;
  display: inline-block;
}

.all-drop-menu-list {
  position: absolute;
  top: 101%;
  left: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  width: min-content;
  z-index: 1;
  overflow: hidden;
  background-color: var(--white-01-color);
  border: var(--border-02);
  border-right: none;
  padding: 15px 15px 15px 0;
}
html[dir="rtl"] .all-drop-menu-list{
  padding: 15px 0 15px 15px;

}
.dropdown-menu---1 {
  list-style: none;
  margin: 0;
  padding: 0;
  width: max-content;
  z-index: 1;
  overflow-y: auto;
  height: 100%;
  max-height: 250px;
}

.dropdown-item---1 {
  padding: 10px;
  cursor: pointer;
}
html[dir="rtl"] .dropdown-item---1{
  font-weight: 600;
}
.dropdown-item---1:hover {
  background-color: #f1f1f1;
}

.checkbox-input {
  display: flex;
  align-items: center;
}

.checkbox-input input {
  margin-right: 10px;
}

:is(.list-city-content, .dropdown-menu---1)::-webkit-scrollbar {
  width: 10px !important;
}

:is(.list-city-content, .dropdown-menu---1)::-webkit-scrollbar-track {
  background: #f2f2f2;
}

:is(.list-city-content, .dropdown-menu---1)::-webkit-scrollbar-thumb {
  background: var(--main-01-color);
  width: 12px !important;
  border-radius: 10px;
  height: 10px !important;
}
