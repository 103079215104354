.main-banner-home .banner-one {
  height: 460px;
  border-radius: var(--border-radius-24);
}

.main-banner-home .banner-main-area .section-padding {
  padding: 0;
}

.main-banner-home .banner-main-area .bg-image::after {
  background: linear-gradient(
      74.64deg,
      rgba(0, 32, 126, 0) 39.23%,
      rgba(3, 23, 83, 0.3) 77.68%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.37));
  border-radius: var(--border-radius-24);
}

.main-banner-home .content-slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  position: absolute;
  top: 100%;
  transform: translateY(150%);
  padding-left: 50px;
}

.main-banner-home .content-slide .title {
  font-size: 25px;
  padding-bottom: 13px;
  max-width: initial;
  margin: initial !important;
}

@media screen and (max-width: 767px) {

  .main-banner-home .content-slide {

    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,115%);
    padding-left: 0;
    text-align: center !important;
    margin: auto;
    width: 100%;
  }
  html[dir="rtl"] .main-banner-home .content-slide .title{
    text-align: center !important;
  }
  html[dir="rtl"] .main-banner-home .content-slide{
    padding: 0 !important;
  }
}




/* SCREEN 450PX */
@media screen and (max-width: 450px) {
  .main-banner-home .banner-one {
    height: 380px;
    border-radius: 25px;
  }
  .main-banner-home .content-slide {

    transform: translate(-50%,100%);

  }

}
