.input-field-info {
  position: relative;
}

.input-field-info label {
  position: absolute;
  left: 10px;
  top: 25%;
  transition: top 0.3s ease, font-size 0.3s ease;
  pointer-events: none;
  margin: 0 !important;
}

.input-field-info label.active {
  top: 5%;
  color: var(--gray-01-color);
}

.input-field-info input {
  padding: 20px 12px 15px 12px !important;
  transition: border-color 0.3s ease;
}

.text-area-input label {
  top: 5%;
}

.text-area-input textarea {
  padding-top: 25px !important;
}

.text-area-input label.active {
  top: 3%;
}
