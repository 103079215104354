.all-padding {
  padding: 70px 0 30px 0;
}

.all-check-inputs-form .form-check {
  gap: 35px;
  display: flex;
  align-items: center;
}

.table-content-info {
  max-width: 150rem;
  width: 100%;
  overflow-x: auto;
}

/* .table-content-info .table {
  width: 200rem;
} */
.table-content-info .table tr td,
.table-content-info .table tr th {
  border: var(--border-02) !important;
  padding: 15px !important;
}

.table-content-info-message {
  max-width: 100%;
}

.table-content-info-message .table {
  width: 100%;
}

.btn-content-edit {
  padding: 10px 25px !important;
}

.table-content-info .table td {
  vertical-align: middle;
}

.title-admin {
  font-size: 20px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.bttDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.admin-error-data {
  font-size: 25px;
  font-weight: 600;
  color: rgb(196, 9, 9);
  text-align: center;
}

.num-count-info {
  text-align: left;
  padding-bottom: 22px;
  font-size: 18px;
}

html[dir="rtl"] .num-count-info {
  text-align: right;
}

html[dir="rtl"] .all-check-inputs-form {
  margin: 0 0.2rem 0 0 !important;
}

/* CHNAGE SCROLL BAR TABLE */
.table-content-info::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.table-content-info::-webkit-scrollbar-track {
  background: #dbdada;
}

.table-content-info::-webkit-scrollbar-thumb {
  background: var(--main-01-color);
  border-radius: 6px;
}

.messages-container .message {
  border: 1px solid #ccc;
  padding: 16px;
  margin: 15px 0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.messages-container .message .message-name,
.messages-container .message .message-phone {
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-01-color);
}

.messages-container .message .message-phone {
  padding: 20px 0 10px 0;
}

.messages-container .message .message-text {
  font-size: 16px;
  font-weight: 600;
  color: var(--dark-01-color);
}

.messages-container {
  position: relative;
}

.messages-container .remove-btn {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(155, 9, 9);
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.form-admin {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

/* SCREEN 991px */
@media screen and (max-width: 991px) {
  .table-content-info-message {
    max-width: 80rem;
  }

  .table-content-info-message .table {
    width: 80rem;
  }
}

.message-cell {
  max-width: 200px;
  /* Adjust the width as needed */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* SCREEN 480PX */
@media screen and (max-width: 480px) {
  .buttons-top-admin :is(.main-buttons-top, .btn-main),
  .all-padding .btn-main {
    width: 100%;
  }

  .admin-error-data {
    font-size: 19px;
  }

  .form-admin {
    padding: 30px 17px;
  }

  .buttons-excel-add {
    margin-bottom: 20px;
  }
}

.btn-green-execl {
  background: green;
  border-color: green;
}
