.faq-home {
  padding-bottom: 10px;
}

.faq-home .main-faq-title {
  text-align: center;
  padding-bottom: 40px;
}

.faq-home .main-faq-title .title {
  font-size: 27px;
  color: var(--dark-01-color);
  padding-bottom: 15px;
}
html[dir="rtl"] .faq-home .main-faq-title .title {
  font-size: 30px;
  font-weight: 700;
}

.faq-home .main-faq-title .text {
  font-size: 17px !important;
  font-weight: 400;
  color: var(--dark-01-color);
  line-height: 1.5;
}
.faq-home .all-faq-home {
  max-width: 750px;
  width: 100%;
  margin: auto;
}
.faq-home .all-faq-home .faq-one {
  margin-bottom: 15px !important;
  border: var(--border-02);
  border-radius: 8px;
  overflow: hidden;
}

.faq-home .all-faq-home .accordion {
  --bs-accordion-bg: var(--light-blue-01-color) !important;
}

.accordion-button::after {
  background-image: none !important;
  content: "\f067" !important;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 17px;
  display: inline-block;
  margin: 0 0 0 auto !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  content: "\f068" !important;
  transform: none !important;
}

.faq-home .all-faq-home .faq-one .accordion-button {
  font-size: 17px;
  font-weight: 800;
  color: var(--dark-01-color);
  padding: 20px 15px;
  text-align: left !important;
  line-height: 1.5;
}

.faq-home .all-faq-home .faq-one .accordion-body {
  font-size: 15px;
  font-weight: 400;
  color: var(--dark-01-color);
  line-height: 1.8;
  text-align: left !important;
}

.faq-home .accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 206, 143, 0.082);
}

.faq-home .accordion-button:not(.collapsed) {
  background: var(--green-02-color);
}

@media screen and (max-width: 550px) {
  .faq-home .all-faq-home .faq-one .accordion-button {
    font-size: 14px;
  }
}
