.not-found-page {
padding: 0px 0 80px 0;
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
}


.not-found-page .animtion-lottie {
  width: 500px;
}

.content-info-error .title {
  font-size: 25px;
  font-weight: 600;
  color: rgb(177, 14, 14);
  padding: 0px 0 25px 0;
}

@media screen and (max-width: 768px) {
  .not-found-page .animtion-lottie {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .content-info-error .title {
    font-size: 25px;
  }
}
