.header-content-properties .all-header-properties .title {
    font-size: 25px;
    font-weight: 600;
    color: var(--dark-01-color);
    padding-bottom: 30px;
}

.header-content-properties .all-header-properties .link-one-pro {
    background: var(--light-blue-01-color);
    padding: 9px 20px;
    border-radius: 50px;
    width: fit-content;
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-01-color);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
    transition: all 0.5s;
}

.header-content-properties .all-header-properties .link-one-pro:hover {
    background: var(--main-01-color);
    color: var(--white-01-color);
}

.header-content-properties .all-header-properties .pressed {
    background: var(--main-01-color);
    color: var(--white-01-color);
}
.header-content-properties .all-header-properties .pressed svg {
    stroke: var(--white-01-color);
}

.header-content-properties .all-header-properties .link-one-pro:hover svg {
    stroke: var(--white-01-color);
}

.header-content-properties .list-properties {
    overflow-x: auto;
    padding-bottom: 15px;
}

.header-content-properties .list-properties {
    flex-wrap: wrap;
}

@media screen and (max-width:650px) {
    .header-content-properties .list-properties {
        flex-wrap: nowrap;
    }

    .header-content-properties .list-properties::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    .header-content-properties .list-properties::-webkit-scrollbar-track {
        background: var(--light-blue-01-color);
        border-radius: 10px;

    }

    .header-content-properties .list-properties::-webkit-scrollbar-thumb {
        background: var(--main-01-color);
        border-radius: 10px;
    }

}

@media screen and (max-width:450px) {
    .header-content-properties .all-header-properties .title {
        font-size: 18px;
    }
}