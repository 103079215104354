
.modal-success .content-info-modal-success .icon-success{
    width: 100px;
    height: 100px;
    background: var(--light-blue-01-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.modal-success .content-info-modal-success{
    text-align: center;
}
.modal-success .content-info-modal-success .title{
    font-size: 22px;
    color: var(--dark-01-color);
    padding: 17px 0;
}
.modal-success .content-info-modal-success .text{
    font-size: 15px;
    font-weight: 500;
    color: var(--dark-01-color);
    line-height: 1.8;
}
.modal-success .btn-main{
    border-radius: 50px;
    margin-top: 17px;
}