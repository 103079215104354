.form-contact-page .info-contact-page {
    background: var(--light-blue-01-color);
    padding: 30px 20px;
    border-radius: var(--border-radius-24);
}

.form-contact-page .info-contact-page .title {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark-01-color);
}

.form-contact-page .info-contact-page .text,
.form-contact-page .info-contact-details .link-contact {
    font-size: 16px;
    font-weight: 400;
    color: var(--dark-01-color);
    padding: 20px 0;
    line-height: 1.5;
}

.form-contact-page .info-contact-details .link-contact {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px 0 0 0;
    line-height: initial;
    transition: all 0.5s;

}

.form-contact-page .info-contact-details .link-contact:hover {
    color: var(--main-01-color);
}

.form-contact-page .info-contact-details .link-contact:not(:first-child) {
    border-top: var(--border-02);
}

@media screen and (max-width:480px) {
    .form-contact-page .info-contact-page .text {
        font-size: 14px;
    }
}