.header-details-properties {
    padding-top: 14px;
}

.header-details-properties .all-header-details-properties .left-header-details .title {
    font-size: 23px;
    font-weight: 600;
    color: var(--dark-01-color);
    text-transform: capitalize;
}

.header-details-properties .all-header-details-properties .left-header-details .text {
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-01-color);
    padding-top: 5px;
}

@media screen and (max-width:450px) {
    .header-details-properties .all-header-details-properties :is(.right-info-details, .right-info-details .btn-main) {
        width: 100%;
    }

    .header-details-properties .all-header-details-properties .left-header-details .title {
        font-size: 18px;

    }

    .header-details-properties .all-header-details-properties .left-header-details .text {
        font-size: 14px;

    }
}