.main-about .main-about-content .image-main-about img {
  border-radius: var(--border-radius-24);
}

.main-about .main-about-content .title {
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 15px;
  text-transform: capitalize;
}

.main-about .main-about-content .text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.4399999976158142px;
  padding-bottom: 12px ;
}
@media screen and (max-width:767px) {
    .main-about .image-main-about{
        margin-bottom: 15px;
    }
    .main-about .image-main-about img{
        height: 350px;
    }
}

