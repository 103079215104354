.banner-main-area {
  position: relative;
}

.banner-main-area .bg-image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.banner-main-area .bg-image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 50%);
  top: 0;
  left: 0;
}

.banner-main-area .section-padding {
  padding: 260px 0;
}

.banner-one {
  position: relative;
  z-index: 1;
}

.banner-main-area .info-banner {
  text-align: center;
}

.banner-main-area .info-banner :is(.text-top, .text) {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: var(--white-01-color);
}

.banner-main-area .info-banner .text-top {
  font-size: 20px;
  width: 100%;
  text-transform: capitalize;
}

.banner-main-area .info-banner :is(.title, .text) {
  max-width: 700px;
  width: 100%;
  margin: auto !important;
}

.info-banner .content-slide .title {
  font-size: 46px;
  font-weight: 600;
  color: var(--white-01-color);
  line-height: 1.3;
  max-width: 800px;
  padding: 10px 0;
  text-transform: capitalize;
}

/* SCREEN 1199PX */
@media screen and (max-width: 1199px) {
  .banner-main-area .section-padding {
    padding: 220px 0;
  }
}

/* SCREEN 991PX */
@media screen and (max-width: 991px) {
  .banner-main-area .section-padding {
    padding: 180px 0;
  }
}

/* SCREEN 767PX */
@media screen and (max-width: 767px) {
  .banner-main-area .info-banner .content-slide .title {
    font-size: 38px;
    line-height: 1.5;
  }
}

/* SCREEN 550PX */
@media screen and (max-width: 550px) {
  .banner-main-area .section-padding {
    padding: 150px 0 85px 0;
  }

  .banner-main-area .info-banner .content-slide .title {
    font-size: 35px;
  }
}

/* SCREEN 450PX */
@media screen and (max-width: 450px) {
  .info-banner .content-slide .text-top {
    font-size: 17px;
  }

  .banner-main-area .info-banner .content-slide .title {
    font-size: 30px;
  }

  .info-banner .content-slide .text {
    font-size: 14px;
  }
}

/* SCREEN 450PX */
@media screen and (max-width: 390px) {
  .banner-main-area .info-banner .content-slide .title {
    font-size: 26px;
  }
}
