.filter-category .search-filter {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main-01-color);
  border-radius: 50%;
  margin-left: 30px;
  cursor: pointer;
}

.filter-category {
  display: flex;
  align-items: center;
  border: var(--border-02);
  background: var(--white-01-color);
  margin-top: 50px;
  width: min-content;
  justify-content: space-between;
  padding-right: 32px;
  border-radius: 50px;
  margin: 50px auto 0 auto;
}

.filter-category .button-drop {
  width: 200px;
  border-radius: 0;
  background: transparent;
  padding: 25px 0;
  display: flex;
  border: none;
  position: relative;
}

.filter-category .dropdown---1 .button-drop::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 60%;
  background: #e5e7e7;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
}

.filter-category
  .drop-filter-one:nth-child(4)
  > .dropdown---1
  > .button-drop::after {
  display: none;
}
.filter-category .button-drop:hover {
  background: transparent;
  color: var(--dark-01-color);
}

.filter-category .button-drop:hover svg path,
.filter-category .button-drop:hover .info-drop-menu-btn svg path {
  fill: transparent !important;
  stroke: #969595 !important;
}

@media screen and (max-width: 991px) {
  .filter-category {
    flex-wrap: wrap;
    width: 100%;
    padding: 25px;
    border-radius: 16px;
    gap: 10px;
  }

  .filter-category .button-drop {
    width: 100%;
    border: var(--border-02);
    border-radius: 15px;
    padding: 17px 15px;
    justify-content: space-between;
  }

  .filter-category .search-filter {
    width: 100%;
    border-radius: 50px;
    margin: 15px 0 0 0;
  }

  .drop-filter-one {
    width: 49%;
    flex: 0 1 auto;
  }

  .filter-category .button-drop::after {
    display: none;
  }
  .filter-category :is(.dropdown---1, .dropdown-menu---1, .all-drop-menu-list) {
    width: 100%;
  }
}

@media screen and (max-width: 598px) {
  .drop-filter-one {
    width: 48%;
  }
}
@media screen and (max-width: 515px) {
  .filter-category {
    padding: 25px 15px;
  }
  .drop-filter-one {
    width: 100%;
    flex: 100%;
  }
}

.property-filter-content .dropdown-menu---1 {
  width: 100%;
}

/* =================================
START RAANGE PRICE FILTER
===================================*/

.price-filter-content .title {
  padding-bottom: 0;
}

.price-filter-content :is(.MuiSlider-thumb, .MuiSlider-track, .MuiSlider-rail) {
  background: var(--main-01-color);
}

.price-filter-content .MuiSlider-root {
  color: var(--main-01-color);
}

.price-filter-content .MuiSlider-thumb:hover,
.price-filter-content .MuiSlider-thumb:focus,
.price-filter-content .MuiSlider-thumb:active {
  box-shadow: 0px 0px 0px 8px rgb(16 17 18 / 16%);
}

.price-filter-drop {
  padding: 0 !important;
  height: auto !important;
}
.price-filter-drop .price-filter-content {
  padding: 20px;
}
.price-filter-drop .dropdown-menu---1 {
  width: 400px;
  overflow: hidden;
}

.price-filter-drop .main-price-input {
  text-align: left;
}

.price-filter-drop .main-price-input .form-label {
  margin-bottom: 8px !important;
}
.price-filter-drop .row > * {
  padding-left: 0 !important;
}

@media screen and (max-width: 991px) {
  .price-filter-drop .dropdown-menu---1 {
    width: 100%;
  }
}

/* =================================
END RAANGE PRICE FILTER
===================================*/
/* ==============================
START CITY SEACRCH FILTER 
==================================*/
.search-city-filter .dropdown-menu---1 {
  width: max-content;
  overflow: hidden;
}

.search-city-filter {
  height: auto !important;
  padding: 15px !important;
}
.search-city-filter input {
  border-radius: 50px;
  padding: 15px 20px !important;
  background: var(--light-blue-01-color);
}
.search-city-filter input:focus {
  box-shadow: none !important;
}
.search-city-filter .list-city-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 20px 0 0;
}
html[dir="rtl"] .search-city-filter .list-city-content {
  padding: 0 0 0 20px;
}
.search-city-filter .list-city-content .list-one-search {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 500;
  color: var(--dark-01-color);
}
.search-city-filter .icon-search-filter-1 svg path {
  stroke: var(--gray-01-color);
}
.search-city-filter .search-input-filter {
  position: relative;
  margin-bottom: 15px;
}
.search-city-filter .search-input-filter .icon-search-filter-1 {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
html[dir="rtl"] .search-city-filter .search-input-filter .icon-search-filter-1 {
  right: initial;
  left: 20px;
}
.search-city-filter .list-city-content {
  overflow-y: auto;
  height: 100%;
  max-height: 200px;
}

@media screen and (max-width: 991px) {
  .search-city-filter .dropdown-menu---1 {
    width: 100%;
  }
}
/* ==============================
END CITY SEACRCH FILTER 
==================================*/
