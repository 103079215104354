/* START SPINNER LOADER */
.loader-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: var(--white-01-color);
  bottom: 0;
  z-index: 1000;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-wrapper.hidden {
  animation: hidden 2s;
  animation-fill-mode: forwards;
}

@keyframes hidden {
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

.loader--1 {
  width: 64px;
  height: 64px;
  position: relative;
  background-image: linear-gradient(#00207e 16px, transparent 0),
    linear-gradient(#ff3d00 16px, transparent 0),
    linear-gradient(#ff3d00 16px, transparent 0),
    linear-gradient(#00207e 16px, transparent 0);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top, left bottom, right top, right bottom;
  animation: rotate 1s linear infinite;
}
@keyframes rotate {
  0% {
    width: 64px;
    height: 64px;
    transform: rotate(0deg);
  }
  50% {
    width: 30px;
    height: 30px;
    transform: rotate(180deg);
  }
  100% {
    width: 64px;
    height: 64px;
    transform: rotate(360deg);
  }
}
