.card-product-one {
  border: var(--border-02);
  border-radius: 16px;
  overflow: hidden;
  background: var(--white-01-color);
  cursor: pointer;
}

.card-product-one .badge-info-text {
  background: var(--light-blue-01-color);
  padding: 7px 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--main-01-color);
  width: fit-content;
  border-radius: 50px;
  margin-top: 15px;
}

.card-product-one .badge-info-text svg {
  width: 20px;
  height: 20px;
}

.card-product-one .image-card-product img {
  border-radius: 16px 16px 0 0;
}

.card-product-one .details-card-product {
  padding: 12px;
}

.card-product-one .details-card-product .title {
  font-size: 16px;
  font-weight: 800;
  line-height: 22.4px;
  text-transform: capitalize;
  color: var(--dark-01-color);
}

.card-product-one .details-card-product .delivery-date {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-01-color);
  padding: 7px 0px;

  margin-top: 10px;
}
.card-product-one .details-card-product .down-payment span {
  font-size: 16px;
  font-weight: 800;

}
.card-product-one .details-card-product .delivery-date .date-info {
  color: var(--dark-01-color);
  font-weight: 600;
}

.card-product-one .details-card-product .text-content {
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  color: var(--gray-01-color);
  padding-top: 8px;
  text-transform: capitalize;
}

.card-product-one .details-card-product .details-product {
  border-block: var(--border-03);
  border-width: 2px;
  padding: 15px 0;
  margin: 15px 0;
}

.card-product-one
  .details-card-product
  .details-product
  .details-one-content
  .text-info-details {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-top: 3px;
}

.card-product-one
  .details-card-product
  .details-product
  .details-one-content
  .icon-svg
  svg {
  width: 20px;
  height: 20px;
}

.card-product-one .details-card-product .details-product .details-one-content {
  position: relative;
}

.card-product-one
  .details-card-product
  .details-product
  .details-one-content:not(:last-child):after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  background: #dcdcdc;
  right: -37%;
  border-radius: 5px;
}

.card-product-one .price-content sub {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.card-product-one .price-content .price-num {
  font-size: 20px;
  display: block;
}

@media screen and (max-width: 767px) {
  .card-product-one .details-card-product .text-content {
    font-size: 15px;
  }
  .card-product-one .details-card-product .delivery-date {
    font-size: 15px;
  }
  .card-product-one .price-content .price-num {
    font-size: 24px;
  }
}

/* SCREEN 450PX */
@media screen and (max-width: 550px) {
  .card-product-one
    .details-card-product
    .details-product
    .details-one-content:not(:last-child):after {
    right: -65%;
  }
  .card-product-one .details-card-product .details-product {
    gap: 2.5rem !important;
  }
  .card-product-one
    .details-card-product
    .details-product
    .details-one-content {
    gap: 0.5rem !important;
  }
}
